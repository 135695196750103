import React, { useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import useScroll from "../components/useScroll";
import useActiveLink from "../components/useActiveLink";
import logoLight from "../assets/images/logo-light.png";
import logoDark from "../assets/images/logo-dark.png";
import { useLanguage } from "../context/LanguageContext";
// import LanguageSwitcher from "../components/LanguageSwitcher";
// import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../components/LanguageSwitcher";

const NavbarComponent = () => {
  const { language } = useLanguage();

  const navClass = useScroll();
  const { activeLink, handleLinkClick } = useActiveLink(
    ".navbar-nav li.active"
  );
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <React.Fragment>
      <nav
        className={`navbar navbar-expand-lg fixed-top navbar-custom transparent-sticky ${navClass} ${
          language === "fa" ? "rtl" : ""
        }`}
        id="navbar"
      >
        <Container>
          <Link className="navbar-brand logo" to="#">
            <img src={logoLight} className="logo-light" alt="" height="32" />
            <img src={logoDark} className="logo-dark" alt="" height="32" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={toggle}
          >
            <i className="mdi mdi-menu"></i>
          </button>
          <Navbar.Collapse id="navbarSupportedContent" in={isOpen}>
            <ul className="navbar-nav ms-auto navbar-center">
              <li
                className={`nav-item ${activeLink === "/home" ? "active" : ""}`}
              >
                <Nav.Link href="#home" onClick={() => handleLinkClick("/home")}>
                  {language === "en" ? "Home" : "خانه"}
                </Nav.Link>
              </li>
              <li
                className={`nav-item ${
                  activeLink === "/about" ? "active" : ""
                }`}
              >
                <Nav.Link
                  href="#about"
                  onClick={() => handleLinkClick("/about")}
                >
                  {language === "en" ? "About" : "درباره"}
                </Nav.Link>
              </li>
              <li
                className={`nav-item ${
                  activeLink === "/services" ? "active" : ""
                }`}
              >
                <Nav.Link
                  href="#services"
                  onClick={() => handleLinkClick("/services")}
                >
                  {language === "en" ? "Services" : "خدمات"}
                </Nav.Link>
              </li>
              <li
                className={`nav-item ${
                  activeLink === "/projects" ? "active" : ""
                }`}
              >
                <Nav.Link
                  href="#projects"
                  onClick={() => handleLinkClick("/projects")}
                >
                  {language === "en" ? "projects" : "‌پروژه‌ها"}
                </Nav.Link>
              </li>
              <li
                className={`nav-item ${
                  activeLink === "/experience" ? "active" : ""
                }`}
              >
                <Nav.Link
                  href="#experience"
                  onClick={() => handleLinkClick("/experience")}
                >
                  {language === "en" ? "Experience" : "تجربه"}
                </Nav.Link>
              </li>
              <li
                className={`nav-item ${activeLink === "/team" ? "active" : ""}`}
              >
                <Nav.Link href="#team" onClick={() => handleLinkClick("/team")}>
                  {language === "en" ? "Team" : "تیم"}
                </Nav.Link>
              </li>
              <li
                className={`nav-item ${
                  activeLink === "/contact" ? "active" : ""
                }`}
              >
                <Nav.Link
                  href="#contact"
                  onClick={() => handleLinkClick("/contact")}
                >
                  {language === "en" ? "Contact" : "تماس"}
                </Nav.Link>
              </li>
              <li className="nav-item d-lg-none">
                <LanguageSwitcher />
              </li>
            </ul>
          </Navbar.Collapse>
          <div className="d-none d-lg-block ms-3">
            <LanguageSwitcher />
          </div>
        </Container>
      </nav>
    </React.Fragment>
  );
};

export default NavbarComponent;
