import React from "react";
import { FaUser } from "react-icons/fa";

const TeamMemberImageDefault = () => {
  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        paddingTop: "100%", // This creates a square aspect ratio
        backgroundColor: "#D3D3D3", // Lighter grey color
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "6px 6px 0px 0px",
      }}
    >
      <FaUser
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          color: "white",
          fontSize: "10rem",
          borderRadius: "6px 6px 0px 0px",
          padding: "10px",
        }}
      />
    </div>
  );
};

export default TeamMemberImageDefault;
