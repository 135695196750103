import React from "react";
import { Col, Container, Nav, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

// Image Imports
import vcafeGholhak from "../../assets/images/works/vcafe-gholhak.jpg";
import vcafeFelestin from "../../assets/images/works/vcafe-felestin.jpeg";
import vcafePasdaran from "../../assets/images/works/vcafe-pasdaran.jpg";
import patiss from "../../assets/images/works/patis.jpg";
import ferment from "../../assets/images/works/ferment.jpg";
import samGym from "../../assets/images/works/sam-gym.jpg";
import nineteen from "../../assets/images/works/nineteen.jpg";
import samFoodHall from "../../assets/images/works/sam-food-hall.jpg";
import gooshe from "../../assets/images/works/gooshe.jpg";

import { useTranslation } from "react-i18next";
import { useLanguage } from "../../context/LanguageContext";

interface PortfolioData {
  image: string;
  groups: string[];
  title: {
    en: string;
    fa: string;
  };
  category: string;
  isConsulting?: boolean;
}

const portfolioData: PortfolioData[] = [
  // Personal Projects
  {
    image: vcafeGholhak,
    groups: ["Personal"],
    title: { en: "Vcafe Gholhak", fa: "وی کافه قلهک" },
    category: "Personal",
  },
  {
    image: vcafeFelestin,
    groups: ["Personal"],
    title: { en: "Vcafe Felestin", fa: "وی کافه فلسطین" },
    category: "Personal",
  },
  {
    image: vcafePasdaran,
    groups: ["Personal"],
    title: { en: "Vcafe Pasdaran", fa: "وی کافه پاسداران" },
    category: "Personal",
  },
  {
    image: patiss,
    groups: ["Personal"],
    title: { en: "Patiss Bakery", fa: "پاتیس بیکری" },
    category: "Personal",
  },
  {
    image: ferment,
    groups: ["Personal"],
    title: { en: "Ferment Cafe", fa: "فرمنت کافه" },
    category: "Personal",
  },
  {
    image: samGym,
    groups: ["Personal"],
    title: { en: "Sam Gym", fa: "باشگاه سام" },
    category: "Personal",
  },

  // Consulting Projects
  {
    image: samFoodHall,
    groups: ["Consulting"],
    title: { en: "Sam Food Hall", fa: "سام فودهال" },
    category: "Consulting",
    isConsulting: true,
  },
  {
    image: gooshe,
    groups: ["Consulting"],
    title: { en: "Gooshe Cafe", fa: "کافه گوشه" },
    category: "Consulting",
    isConsulting: true,
  },
  {
    image: nineteen,
    groups: ["Consulting"],
    title: { en: "Nineteen Project", fa: "پروژه ناینتین" },
    category: "Consulting",
    isConsulting: true,
  },
  // {
  //   image: samGym,
  //   groups: ["Consulting"],
  //   title: { en: "Darchin Cafe", fa: "کافه دارچین" },
  //   category: "Consulting",
  //   isConsulting: true,
  // },
];

const Project: React.FC = () => {
  const { t } = useTranslation();
  const { language } = useLanguage();

  const [filter, setFilter] = React.useState("All");

  const handleFilter = (group: string) => {
    setFilter(group);
  };

  const filteredData =
    filter === "All"
      ? portfolioData
      : portfolioData.filter((item) => item.groups.includes(filter));

  return (
    <React.Fragment>
      <section className="section portfolio business-portfolio" id="projects">
        <Container>
          <Row className="justify-content-center">
            <Col lg={6}>
              <div className="text-center">
                <h2 className="heading-title-title text-uppercase">
                  {t("project-title")}
                </h2>
                <p className="heading-title-desc text-muted mt-4 mb-0">
                  {t("project-desc")}
                </p>
              </div>
            </Col>
            <Col lg={8} className="mt-5">
              <div className="filters-group-wrap">
                <div className="filters-group">
                  <Nav className="filter-options justify-content-center mt-3">
                    <li
                      onClick={() => handleFilter("All")}
                      className={`nav-link list-inline-item ${
                        filter === "All" ? "active" : ""
                      }`}
                      style={{ cursor: "pointer" }}
                    >
                      {language === "en" ? "All" : "همه"}
                    </li>
                    <li
                      onClick={() => handleFilter("Personal")}
                      className={`nav-link list-inline-item ${
                        filter === "Personal" ? "active" : ""
                      }`}
                      style={{ cursor: "pointer" }}
                    >
                      {language === "en" ? "Personal" : "شخصی"}
                    </li>
                    <li
                      onClick={() => handleFilter("Consulting")}
                      className={`nav-link list-inline-item ${
                        filter === "Consulting" ? "active" : ""
                      }`}
                      style={{ cursor: "pointer" }}
                    >
                      {language === "en" ? "Consulting" : "مشاوره"}
                    </li>
                  </Nav>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <Container className="mt-3">
          <Row id="grid">
            {(filteredData || []).map((item: PortfolioData, key: number) => (
              <Col
                lg={4}
                key={key}
                className="picture-item"
                data-groups={JSON.stringify(item.groups)}
              >
                <div className="gallary-box m-3 portfolio position-relative">
                  <Link className="lightbox port" to="#" title="">
                    <img
                      className="gallary-container"
                      src={item.image}
                      alt={item.title.en}
                    />
                    <div className="item-effect">
                      <div className="item-caption text-center bg-primary text-white p-3">
                        <h5 className="f-14 text-uppercase mb-0">
                          {language === "fa" ? item.title.fa : item.title.en}
                        </h5>
                      </div>
                    </div>
                  </Link>
                  {item.isConsulting && (
                    <div
                      className="consulting-label position-absolute"
                      style={{
                        top: "10px",
                        left: "10px",
                        background: "rgba(0, 0, 0, 0.7)",
                        color: "white",
                        padding: "5px 10px",
                        borderRadius: "5px",
                        fontSize: "12px",
                      }}
                    >
                      {language === "en" ? "Consulting" : "مشاوره"}
                    </div>
                  )}
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Project;
