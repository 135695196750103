import React, { useState } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import AliAzim from "../../assets/images/team/ali-azim.jpg";
import LhamSdq from "../../assets/images/team/lham-sdq.jpg";
import AliSaadat from "../../assets/images/team/ali-saadat.jpg";
import AlirezaRahAnjam from "../../assets/images/team/alireza-rahanjam.jpg";
import SaharBahonar from "../../assets/images/team/sahar-bahonar.jpeg";
import MohamadMahmoudi from "../../assets/images/team/mohamad-mahmoudi.jpg";
import MiladPezeshki from "../../assets/images/team/milad-pezeshki.jpg";
import ArdavanAsharoun from "../../assets/images/team/ardavan-asharoun.jpg";
import ShahabShahrAshoub from "../../assets/images/team/shahab-shahrashob.jpg";
import MiladKazem from "../../assets/images/team/milad-kazem.jpg";
import MohamadDeljoo from "../../assets/images/team/mohamad-deljoo.jpg";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import TeamMemberImageDefault from "./TeamMemberImageDefault";

const teamMembers = [
  {
    id: 9,
    name_en: "Siamak Ghasemi",
    name_fa: "سیامک قاسمی",
    image: null,
    position_en: "Co Founder",
    position_fa: "بنیان گذار",
    caption_en: "",
    caption_fa: "",
  },
  {
    id: 2,
    name_en: "Ali Saadat",
    name_fa: "علی سعادت",
    image: AliSaadat,
    position_en: "Co Founder",
    position_fa: "بنیان گذار",
    caption_en: "",
    caption_fa: "",
  },

  {
    id: 1,
    name_en: "Ali Azimoshan",
    name_fa: "علی عظیم الشان",
    image: AliAzim,
    position_en: "Software engineer",
    position_fa: "مهندس نرم افزار",
    caption_en: "",
    caption_fa: "",
  },
  {
    id: 4,
    name_en: "Elham Sadeqi",
    name_fa: "الهام صادقی",
    image: LhamSdq,
    position_en: "Product Designer",
    position_fa: "طراح محصول",
    caption_en: "",
    caption_fa: "",
  },
  {
    id: 5,
    name_en: "Sahar Bahonar",
    name_fa: "سحر باهنر",
    image: SaharBahonar,
    position_en: "Senior Consultant",
    position_fa: "مشاوره ارشد",
    caption_en: "",
    caption_fa: "",
  },
  {
    id: 14,
    name_en: "Ardavan Asharioun",
    name_fa: "اردوان اشعریون",
    image: ArdavanAsharoun,
    position_en: "Photographer",
    position_fa: "عکاس",
    caption_en: "",
    caption_fa: "",
  },
  {
    id: 15,
    name_en: "Mohammad Mahmoudi",
    name_fa: "محمد محمودی",
    image: MohamadMahmoudi,
    position_en: "Designer",
    position_fa: "طراح",
    caption_en: "",
    caption_fa: "",
  },
  {
    id: 8,
    name_en: "Mohammad Deljou",
    name_fa: "محمد دلجو",
    image: MohamadDeljoo,
    position_en: "Executive Team",
    position_fa: "تیم اجرایی",
    caption_en: "",
    caption_fa: "",
  },
  {
    id: 3,
    name_en: "Alireza Rahanjam",
    name_fa: "علیرضا ره‌انجام",
    image: AlirezaRahAnjam,
    position_en: "Executive Team",
    position_fa: "تیم اجرایی",
    caption_en: "",
    caption_fa: "",
  },
  {
    id: 10,
    name_en: "Milad Pezeshki",
    name_fa: "میلاد پزشکی",
    image: MiladPezeshki,
    position_en: "Supply and Maintenance",
    position_fa: "تامین و نگه داری",
    caption_en: "",
    caption_fa: "",
  },
  {
    id: 7,
    name_en: "Milad Kazemzadeh",
    name_fa: "میلاد کاظم زاده",
    image: MiladKazem,
    position_en: "Executive Team",
    position_fa: "تیم اجرایی",
    caption_en: "",
    caption_fa: "",
  },

  {
    id: 11,
    name_en: "Shahab ShahrAshoub",
    name_fa: "شهاب شهرآشوب",
    image: ShahabShahrAshoub,
    position_en: "Marketing",
    position_fa: "مارکتینگ",
    caption_en: "",
    caption_fa: "",
  },
  {
    id: 12,
    name_en: "Sahand Sanjarvash",
    name_fa: "سهند سنجروش",
    image: null,
    position_en: "Executive Team",
    position_fa: "تیم اجرایی",
    caption_en: "",
    caption_fa: "",
  },
  {
    id: 13,
    name_en: "Sina Azizi",
    name_fa: "سینا عزیزی",
    image: null,
    position_en: "Executive Team",
    position_fa: "تیم اجرایی",
    caption_en: "",
    caption_fa: "",
  },
  {
    id: 16,
    name_en: "Danial Ahmadian",
    name_fa: "دانیال احمدیان",
    image: null,
    position_en: "Executive Team",
    position_fa: "تیم اجرایی",
    caption_en: "",
    caption_fa: "",
  },
  {
    id: 17,
    name_en: "Ali Bahrami",
    name_fa: "علی بهرامی",
    image: null,
    position_en: "Executive Team",
    position_fa: "تیم اجرایی",
    caption_en: "",
    caption_fa: "",
  },
  {
    id: 6,
    name_en: "Saba Seyed Rezaei",
    name_fa: "صبا سید رضایی",
    image: null,
    position_en: "Executive Team",
    position_fa: "تیم اجرایی",
    caption_en: "",
    caption_fa: "",
  },
  {
    id: 18,
    name_en: "Amin Tehrani",
    name_fa: "امین طهرانی",
    image: null,
    position_en: "Executive Team",
    position_fa: "تیم اجرایی",
    caption_en: "",
    caption_fa: "",
  },
];

const Team = () => {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.language === "fa"; // Detect RTL language
  const [showAll, setShowAll] = useState(false);

  const handleShowMore = () => {
    setShowAll(!showAll);
  };

  const visibleMembers = showAll ? teamMembers : teamMembers.slice(0, 6);

  return (
    <React.Fragment>
      {/* Team */}
      <section
        className={`section softwere-team ${isRTL ? "rtl" : ""}`}
        id="team"
      >
        <Container>
          <Row className="justify-content-center mb-3">
            <Col lg={8}>
              <div className="text-center">
                <h2 className="heading-title-title text-uppercase">
                  {t("team-title")}
                </h2>
                <p className="heading-title-desc text-muted mt-4">
                  {t("team-desc")}
                </p>
              </div>
            </Col>
          </Row>

          <Row className="pt-0">
            {visibleMembers.map((member) => (
              <Col xs={6} md={6} lg={4} key={member.id} className="">
                <div className="softwere-team-box mt-4">
                  <div className="softwere-team-img">
                    {member.image ? (
                      <Image src={member.image} alt={member.name_en} fluid />
                    ) : (
                      <TeamMemberImageDefault />
                    )}
                  </div>
                  <div className="softwere-team-content text-center mt-2 p-2">
                    <h4 className="software-team-title">
                      {isRTL ? member.name_fa : member.name_en}
                    </h4>
                    <p className="software-team-position">
                      {isRTL ? member.position_fa : member.position_en}
                    </p>
                    <p className="caption mt-3">
                      {isRTL ? member.caption_fa : member.caption_en}
                    </p>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
          <Row className="justify-content-center text-center mt-4">
            <Col>
              <div
                onClick={handleShowMore}
                style={{
                  cursor: "pointer",
                  color: "black",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {showAll ? t("show-less") : t("show-more")}
                {showAll ? (
                  <FaChevronUp className="ml-2" style={{ marginLeft: "8px" }} />
                ) : (
                  <FaChevronDown
                    className="ml-2"
                    style={{ marginLeft: "8px", marginRight: "8px" }}
                  />
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <hr />
    </React.Fragment>
  );
};

export default Team;
